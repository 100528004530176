type Jsonable =
  | string
  | number
  | boolean
  | null
  | undefined
  | readonly Jsonable[]
  | { readonly [key: string]: Jsonable }
  | { toJSON(): Jsonable };

export type BaseErrorOptions = {
  cause?: Error;
  context?: Jsonable;
};

export class BaseError<T extends string> extends Error {
  public override readonly name: T;
  public override readonly cause?: Error;
  public readonly context?: Jsonable;

  constructor(name: T, message: string, options?: BaseErrorOptions) {
    super(message);
    this.name = name;
    this.context = options?.context;
    this.cause = options?.cause;
  }
}
