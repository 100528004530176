import { BaseError, type BaseErrorOptions } from './BaseError';

export enum ErrorName {
  MICROPHONE_ACCESS = 'MICROPHONE_ACCESS',
  EMPTY_RESULT = 'EMPTY_RESULT',
  INVALID_RESULT = 'INVALID_RESULT',
  HTTP = 'HTTP',
}

export class MicrophoneAccessError extends BaseError<ErrorName.MICROPHONE_ACCESS> {
  constructor(message: string, options?: BaseErrorOptions) {
    super(ErrorName.MICROPHONE_ACCESS, message, options);
  }
}

export class EmptyResultError extends BaseError<ErrorName.EMPTY_RESULT> {
  constructor(message: string, options?: BaseErrorOptions) {
    super(ErrorName.EMPTY_RESULT, message, options);
  }
}

export class InvalidResultError extends BaseError<ErrorName.INVALID_RESULT> {
  constructor(message: string, options?: BaseErrorOptions) {
    super(ErrorName.INVALID_RESULT, message, options);
  }
}

export class HTTPError extends BaseError<ErrorName.HTTP> {
  constructor(public readonly statusCode: number, message: string, options?: BaseErrorOptions) {
    super(ErrorName.HTTP, `${statusCode}: ${message}`, options);
  }
}
