import { createContext, useContext } from 'react';
import { type TrackingContext } from './track';

export const Tracking = createContext<TrackingContext | undefined>(undefined);

export const useTrackingContext = () => {
  if (Tracking === undefined) {
    throw new Error('useTrackingContext must be used within a TrackingProvider');
  }

  return useContext(Tracking);
};
