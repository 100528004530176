import { datadogRum } from '@datadog/browser-rum';

function init({
  applicationId,
  clientToken,
  site,
  env,
  appVersion,
  serviceName,
  allowedTracingUrls,
  privacyLevel = 'mask',
  autoTrackUserInteractions = false,
}: {
  serviceName: string;
  applicationId: string;
  clientToken: string;
  site: string;
  env: string;
  appVersion?: string;
  allowedTracingUrls?: string[];
  privacyLevel?: 'allow' | 'mask' | 'mask-user-input';
  autoTrackUserInteractions?: boolean;
}) {
  datadogRum.init({
    service: serviceName,
    applicationId,
    clientToken,
    site,
    env,
    allowedTracingUrls,
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    trackUserInteractions: autoTrackUserInteractions,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: privacyLevel,
    actionNameAttribute: 'data-analytics-name',
    allowFallbackToLocalStorage: true,
  });
}

function sendEvent(eventName: string, eventProperties?: { [key: string]: unknown }) {
  datadogRum.addAction(eventName, eventProperties);
}

function sendError(error: Error, errorProperties?: { [key: string]: unknown }) {
  datadogRum.addError(error, errorProperties);
}

function setUser(user: {
  id: string;
  displayName: string;
  email: string;
  organization?: {
    id: string;
    name: string;
    displayName: string;
  };
  [key: string]: unknown;
}) {
  datadogRum.setUser({
    ...user,
    name: user.displayName,
  });
  startSessionRecording();
}

function getUser(): {
  id: string;
  displayName: string;
  email: string;
  organization?: { id: string; name: string; displayName: string };
  [key: string]: unknown;
} {
  const user = datadogRum.getUser();
  return {
    ...user,
    id: user['id'] as string,
    email: user['email'] as string,
    displayName: user['name'] as string,
  };
}

function clearUser() {
  stopSessionRecording();
  datadogRum.clearUser();
  // NOTE: I don't think we need to stop the session here as we'll lose
  // subsequent events. Clearing the user should be enough.
  // datadogRum.stopSession();
}

function addGlobalProperty(key: string, value: unknown) {
  datadogRum.setGlobalContextProperty(key, value);
}

function removeGlobalProperty(key: string) {
  datadogRum.removeGlobalContextProperty(key);
}

function startSessionRecording() {
  if (canRecordSession()) {
    datadogRum.startSessionReplayRecording();
  }
}

function stopSessionRecording() {
  if (canRecordSession()) {
    datadogRum.stopSessionReplayRecording();
  }
}

function canRecordSession() {
  const allowedRecordSessionAuth0OrgNames = ['tortusai'];
  const userOrgName = getUser()?.organization?.name ?? null;

  if (!userOrgName) {
    return false;
  }
  return allowedRecordSessionAuth0OrgNames.includes(userOrgName);
}

export const analytics = {
  init,
  setUser,
  clearUser,
  getUser,
  addGlobalProperty,
  removeGlobalProperty,
  sendEvent,
  sendError,
  startSessionRecording,
  stopSessionRecording,
};
