import type { AppWindow } from '@tortus/app-platform';

const SIDE_PANEL_WIDTH = 390;
const MIN_DUAL_PANEL_WIDTH = 1000;

export function createWebAppWindow(): AppWindow {
  return {
    positionInMonitor: 'left', // Always
    collapsedWidth: SIDE_PANEL_WIDTH,
    expandedWidth: MIN_DUAL_PANEL_WIDTH,
    isCollapsed: false, // Always
    sidePanelWidth: SIDE_PANEL_WIDTH,

    async expand() {
      // No-op on web
    },

    async collapse() {
      // No-op on web
    },

    async minimise() {
      // No-op on web (handled by the browser)
    },

    async close() {
      // No-op on web (handled by the browser)
    },
  };
}
