import { analytics } from '@tortus/analytics';
import { platform, ServiceType } from '@tortus/app-platform';
import {
  createWebAppInfo,
  createWebAppWindow,
  createWebAudio,
  createWebAuth,
  createWebClipboard,
  createWebDialog,
  createWebFetch,
  createWebLogger,
  createWebUpdater,
  createWebWakeLock,
} from '@tortus/app-platform/web';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

const IS_DEV = Boolean(import.meta.env.DEV);

analytics.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: import.meta.env.VITE_DATADOG_SITE,
  serviceName: import.meta.env.VITE_DATADOG_SERVICE_NAME,
  env: import.meta.env.VITE_DATADOG_ENV,
  privacyLevel: 'mask-user-input',
  autoTrackUserInteractions: true,
  allowedTracingUrls: [import.meta.env.VITE_SERVER_URL, import.meta.env.VITE_API_SERVER_URL],
});

platform
  .bind(ServiceType.AppInfo, createWebAppInfo())
  .bind(ServiceType.AppWindow, createWebAppWindow())
  .bind(ServiceType.Audio, createWebAudio())
  .bind(
    ServiceType.Auth,
    createWebAuth({
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    })
  )
  .bind(ServiceType.Clipboard, createWebClipboard())
  .bind(ServiceType.Dialog, createWebDialog())
  .bind(ServiceType.Fetch, createWebFetch())
  .bind(ServiceType.Logger, createWebLogger())
  .bind(ServiceType.Updater, createWebUpdater())
  .bind(ServiceType.WakeLock, createWebWakeLock());

if (IS_DEV) {
  platform.logger.info('Running in development mode');
  platform.logger.warn(
    'Note that use of <StrictMode/> may cause issues with double-rendering of components'
  );
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

import('@tortus/app-frontend').then(({ App }) =>
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
);
