import type { WakeLock } from '@tortus/app-platform';

export function createWebWakeLock(): WakeLock {
  let wakeLock: WakeLockSentinel | null = null;

  /**
   * We might fail to acquire the wake lock if the user has not granted permission
   * or in other cases such as low battery.
   *
   * We should handle the error in the app and inform the user.
   *
   * @throws {DOMException} If the wake lock request fails
   */
  async function request() {
    if (wakeLock) return;
    wakeLock = await navigator?.wakeLock?.request('screen');
  }

  async function release() {
    if (!wakeLock) return;
    // `release` never throws
    await wakeLock.release();
    wakeLock = null;
  }

  return { request, release };
}
