import type { Updater } from '@tortus/app-platform';

export function createWebUpdater(): Updater {
  async function checkForUpdate(_accessToken: string) {
    // TODO: query the server for updates?
    return null;
  }

  async function installUpdate() {
    // No-op on web
  }

  async function relaunch() {
    location.reload();
  }

  return {
    checkForUpdate,
    installUpdate,
    relaunch,
  };
}
