import { platform, type Audio, type TranscribeAudioArgs } from '@tortus/app-platform';
import { HTTPError } from '@tortus/errors';

// TODO: Not sure about this naming since it could be confused with the WAA.
export function createWebAudio(): Audio {
  async function transcribeAudio({
    endpoint,
    accessToken,
    filePath,
    audioData,
    retryCount = 0,
  }: TranscribeAudioArgs) {
    if (filePath && !audioData) {
      throw new Error('Transcribing from file path is not supported on web');
    }

    // If audio data is provided, post it directly to the endpoint.
    if (audioData) {
      const formData = new FormData();
      formData.append('audio', audioData);
      return platform
        .fetch(endpoint, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data',
            'x-retry-count': retryCount.toString(),
          },
          body: formData,
        })
        .then((response) => {
          if (!response.ok) {
            throw new HTTPError(response.status, response.statusText);
          }
          return response.json();
        });
    }
  }

  return { transcribeAudio };
}
