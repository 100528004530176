import { type Dialog } from '@tortus/app-platform';

export function createWebDialog(): Dialog {
  function confirm(message: string, title?: string): Promise<boolean> {
    return Promise.resolve(
      // We can't change the title of the browser confirm dialog
      window.confirm(title ? `${title}: ${message}` : message)
    );
  }

  return {
    confirm,
  };
}
