import type {
  AppInfo,
  AppWindow,
  Audio,
  Auth,
  Clipboard,
  Config,
  Dialog,
  Fetch,
  Logger,
  Updater,
  WakeLock,
} from './types';

export enum ServiceType {
  AppInfo = 'appInfo',
  AppWindow = 'appWindow',
  Audio = 'audio',
  Auth = 'auth',
  Clipboard = 'clipboard',
  Config = 'config',
  Dialog = 'dialog',
  Fetch = 'fetch',
  Logger = 'logger',
  Updater = 'updater',
  WakeLock = 'wakeLock',
}

export type ServiceTypes = {
  [ServiceType.AppInfo]: AppInfo;
  [ServiceType.AppWindow]: AppWindow;
  [ServiceType.Audio]: Audio;
  [ServiceType.Auth]: Auth;
  [ServiceType.Clipboard]: Clipboard;
  [ServiceType.Config]: Config;
  [ServiceType.Dialog]: Dialog;
  [ServiceType.Fetch]: Fetch;
  [ServiceType.Logger]: Logger;
  [ServiceType.Updater]: Updater;
  [ServiceType.WakeLock]: WakeLock;
};

// Utility type for binding platform implementations to a container.
export type PlatformBinder = (platform: Platform) => void;

class Platform {
  private services: Partial<ServiceTypes> = {};

  public get appInfo(): AppInfo {
    return this.get(ServiceType.AppInfo);
  }

  public get appWindow(): AppWindow {
    return this.get(ServiceType.AppWindow);
  }

  public get audio(): Audio {
    return this.get(ServiceType.Audio);
  }

  public get auth(): Auth {
    return this.get(ServiceType.Auth);
  }

  public get clipboard(): Clipboard {
    return this.get(ServiceType.Clipboard);
  }

  public get config(): Config {
    return this.get(ServiceType.Config);
  }

  public get dialog(): Dialog {
    return this.get(ServiceType.Dialog);
  }

  public get fetch(): Fetch {
    return this.get(ServiceType.Fetch);
  }

  public get logger(): Logger {
    return this.get(ServiceType.Logger);
  }

  public get updater(): Updater {
    return this.get(ServiceType.Updater);
  }

  public get wakeLock(): WakeLock {
    return this.get(ServiceType.WakeLock);
  }

  // Binds a service to the container.
  public bind<T extends ServiceType>(type: T, service: ServiceTypes[T]): Platform {
    this.services[type] = service;
    return this;
  }

  // Binds all services to the container.
  public bindAll(services: ServiceTypes): Platform {
    Object.keys(services).forEach((key) => {
      const type = key as ServiceType;
      this.bind(type, services[type]);
    });
    return this;
  }

  // Retrieves a service by type.
  public get<T extends ServiceType>(type: T): ServiceTypes[T] {
    const service = this.services[type];
    if (!service) {
      throw new ReferenceError(`Service "${type}" not bound to platform`);
    }
    return service;
  }
}

// Export the container.
export const platform = new Platform();
