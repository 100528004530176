import type { AppInfo } from '@tortus/app-platform';

export function createWebAppInfo(): AppInfo {
  function getName() {
    return Promise.resolve('app-web');
  }

  function getVersion() {
    // TODO: get version from CI pipeline
    return Promise.resolve('0.0.1');
  }

  return { getName, getVersion, isNative: false };
}
