import { type AuthSuccessPayload, type Auth, platform } from '@tortus/app-platform';
import { Auth0Client } from '@auth0/auth0-spa-js';

type AuthSuccessCallback = (payload: AuthSuccessPayload) => void;
type AuthErrorCallback = (payload: string) => void;

export function createWebAuth({
  domain,
  clientID,
  audience,
  callbackUrl,
}: {
  domain: string;
  clientID: string;
  audience: string;
  callbackUrl: string;
}): Auth {
  const loginListeners: Set<AuthSuccessCallback> = new Set();
  const errorListeners: Set<AuthErrorCallback> = new Set();

  const authClient = new Auth0Client({
    domain,
    clientId: clientID,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      redirect_uri: callbackUrl,
      scope: 'openid profile email offline_access',
      audience,
      response_type: 'code',
    },
  });

  // Trigger login callback on load, notifing any listeners
  const tokenPayload = loginCallback().then((payload) => {
    if (payload) {
      emitTokenToListeners(payload);
    }
    return payload;
  });

  async function login(email?: string) {
    await authClient.loginWithRedirect({
      authorizationParams: {
        redirect_uri: callbackUrl,
        ...(email ? { login_hint: email } : {}),
      },
    });
  }

  async function loginCallback() {
    try {
      if (location.search.includes('state') && location.search.includes('code')) {
        await authClient.handleRedirectCallback();
      }
      return await silentAuth();
    } catch (error) {
      const err = error as { error: string; error_description: string };
      platform.logger.error('Failed to fetch token silently: ' + JSON.stringify(err));
      switch (err.error) {
        case 'consent_required':
          break;
        case 'login_required':
          emitErrorToListeners('login_required');
          break;
        case 'missing_transaction':
          window.location.href = `${origin}/login`;
          emitErrorToListeners('missing_transaction');
          break;
        default:
          emitErrorToListeners(err?.error);
      }
    }
    return null;
  }

  async function silentAuth() {
    let token = null;
    token = await authClient.getTokenSilently({
      detailedResponse: true,
    });

    if (token) {
      token = {
        access_token: token.access_token,
        refresh_token: '', // Can't retrieve refresh token from silent auth. It's handled by the SDK
        expires_in: token.expires_in,
        token_type: 'Bearer',
        scope: token.scope || '',
      };
      // Set token in cookie in server side route
      await fetch('/api/set-cookie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token.access_token }),
      });
      emitTokenToListeners(token);
    }
    return token;
  }

  async function refresh() {
    await loginCallback();
  }

  function emitTokenToListeners(payload: AuthSuccessPayload) {
    for (const listener of loginListeners) {
      listener(payload);
    }
  }

  function emitErrorToListeners(error: string) {
    for (const listener of errorListeners) {
      platform.logger.debug('Emitting error to listeners: ' + error);
      listener(error);
    }
  }

  async function logout() {
    await authClient.logout({
      logoutParams: {
        returnTo: `${origin}/login`,
      },
    });
    await fetch('/api/remove-cookie', {
      method: 'DELETE',
    });
  }

  function listen(event: 'success', callback: AuthSuccessCallback): () => void;
  function listen(event: 'error', callback: AuthErrorCallback): () => void;
  function listen(
    event: 'success' | 'error',
    callback: AuthSuccessCallback | AuthErrorCallback
  ): () => void {
    switch (event) {
      case 'success':
        loginListeners.add(callback as AuthSuccessCallback);
        tokenPayload.then((token) => token && (callback as AuthSuccessCallback)(token));

        return () => {
          loginListeners.delete(callback as AuthSuccessCallback);
        };

      case 'error':
        errorListeners.add(callback as AuthErrorCallback);

        return () => {
          errorListeners.delete(callback as AuthErrorCallback);
        };
    }
  }

  return { login, logout, listen, refresh };
}
